<template></template>

<script>
export default {
  created() {
    var g = this;
    $.post(api + "/user/login-jwt", {
      jwt: window.location.href.split("/login/")[1],
    }).then(function (d) {
      localStorage.setItem("user", JSON.stringify(JSON.parse(d).response));
      window.location = '/'
    });
  },
};
</script>

<style>
</style>